<template>
  <div id="contacts-form-home-section">
    <contacts-list-form-home-section></contacts-list-form-home-section>
  </div>
</template>
  
<script>
import ContactsListFormHomeSection from './components/ContactsListFormHomeSection'

export default {
  name: 'ContactsFormHomeSection',
  components: { ContactsListFormHomeSection },
  beforeMount() {

  },
  methods: {

  },
}
</script>