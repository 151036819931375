<template>
  <div id="contacts-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>

              <b-col xl="4" sm="12">
                <b-form-group label="Fecha de Inicio" label-for="status">
                  <b-form-datepicker locale="es" id="date_ini" reset-button placeholder="Fecha de inicio"
                    v-model="filtersForm.date_init" />
                </b-form-group>
              </b-col>

              <b-col xl="4" sm="12">
                <b-form-group label="Fecha de Termino" label-for="status">
                  <b-form-datepicker locale="es" id="date_end" reset-button placeholder="Fecha de Termino"
                    v-model="filtersForm.date_end" />
                </b-form-group>
              </b-col>



            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay :show="overlayList" opacity="1.0" spinner-small spinner-variant="primary" @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary"
                  class="d-inline-block" @hidden="_onHidden()">
                  <b-button class="mr-1" ref="button" variant="primary" :disabled="overlayForm"
                    @click.prevent="_exportContactsFormHome()" v-if="permissions_visible.download_contacts">
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    <span class="align-middle">Descargar contactos</span>
                  </b-button>
                </b-overlay>
              </div>


              <div class="scroller mb-1">
                <b-table hover striped show-empty responsive="xl"
                  class="position-relative mb-1 mt-2 has-text-centered tablefix" :items="contactsFormHomeSection.data"
                  :fields="tableColumns" empty-text="Datos no Disponibles">

                  <template #cell(created_at)="data">{{ data.item.created_at | moment("DD/MM/YYYY") }}</template>
                  <template #cell(region)="data"><span v-if="data.item.region != null">{{ data.item.region.name
                  }}</span></template>
                  <template #cell(salary)="data">${{ formatPrice(data.item.salary) }}</template>

                </b-table>
                <b-pagination align="right" v-model="currentPage" hide-goto-end-buttons
                  :total-rows="contactsFormHomeSection.total" :per-page="contactsFormHomeSection.per_page" />
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { mapActions, mapState } from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: 'ContactsListFormHomeSection',
  data() {
    return {
      permissions_visible: {
        edit_contacts: false,
        show_contacts: false,
        download_contacts: false,
        edit_contacts: false,
      },
      overlayForm: false,
      overlayList: true,
      currentPage: 1,
      filtersForm: {
        date_init: "",
        date_end: "",
        page: 1,
      },
      tableColumns: [
        { key: 'id', sortable: false, label: 'ID' },
        { key: 'name', sortable: false, label: 'Nombre' },
        { key: 'email', sortable: false, label: 'Email' },
        { key: 'rut', sortable: false, label: 'Rut' },
        { key: 'method_contact', sortable: false, label: 'Método contacto' },
        { key: 'salary', sortable: false, label: 'Renta' },
        { key: 'region', sortable: false, label: 'Región' },
        { key: 'created_at', sortable: true, label: 'Fecha' },
      ],
    }
  },
  mounted() {
    this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
  },
  beforeMount() {
    this._fetchContactsFormHomeSection(this.filtersForm)
  },
  watch: {
    "filtersForm.date_end": { handler(newval) { this._fetchContactsFormHomeSection(this.filtersForm) } },
    currentPage(val) {
      this.filtersForm.page = this.currentPage
      this._fetchContactsFormHomeSection(this.filtersForm)
    }
  },
  computed: {
    ...mapState('contacts', ['contactsFormHomeSection']),
  },
  methods: {
    ...mapActions('contacts', ['fetchContactsFormHomeSection', 'exportContactsFormHome']),
    async _exportContactsFormHome() {
      this.overlayForm = true
      await this.exportContactsFormHome(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
    },

    async _fetchContactsFormHomeSection(filter) {
      this.overlayList = true
      await this.fetchContactsFormHomeSection(filter)
        .then((res) => {

        })
        .finally(() => {
          this.overlayList = false
        })
    },


    _editContact(newobj) {
      //let data = { name: 'news-details', params: { id: newobj.id, newsingle: newobj }};
      //this.$router.push( data )
    },
    _onHidden() {
      //this.$refs.button.focus()
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
}
</script>
<style>
.tablefix tr {
  height: 61px !important;
}
</style>